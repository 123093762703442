<template>
  <ion-page>
        <ion-header>
            <ion-toolbar>
              <ion-buttons slot="start">
                <ion-back-button default-href="/nodes"></ion-back-button>
              </ion-buttons>
                <ion-title>
                    <div class="header_el">
                        <router-link to="/nodes">
                            <div class="logo_img">
                                <img class="logo_img" src="/assets/Logo-Bianco.png" alt="logo-bianco">
                            </div>
                        </router-link>
                        <div class="title">
                            <h1>|| Trinci-Monitor ||</h1>
                        </div>
                    </div>
                </ion-title>
            </ion-toolbar>
        </ion-header>
      <ion-content>
        <div class="stats">
          <div class="stats_box">
           <div class="stats_details">
            <h5>NODE_ID (Public Key):  {{ id }}</h5>
            <h5 v-if="node.online"><span class="success">ONLINE</span></h5>
            <h5 v-else><span class="danger">OFFLINE</span></h5>
            <h5>Role: {{ node.role || "N/D" }}</h5>
            <h5>PUBLIC KEY: {{ node.public_key || "N/D" }}</h5>
            <h5>NETWORK PUBLIC KEY: {{ node.nw_public_key || "N/D" }}</h5>
            <h5 v-if="node.ip_endpoint">IP END-POINT: {{ node.ip_endpoint || "N/D" }}</h5>
            <h5>ROLE: {{ node.role || "N/D" }}</h5>
            <h5>CORE VERSION: {{ node.core_version || "N/D" }}</h5>
            <h5>SEED: {{ node.seed || "N/D" }}</h5>
            <h5>TIME: {{ node.time || "N/D" }}</h5>
            <h3>Network Configuration:</h3>
             <ul class="config_list">
              <li><h5 v-if="node.nw_config">NAME: {{ node.nw_config.name || "N/D" }}</h5></li>
              <li><h5 v-if="node.nw_config">BLOCK TRESHOLD: {{ node.nw_config.block_threshold || "N/D" }}</h5></li>
              <li><h5 v-if="node.nw_config">BLOCK TIMEOUT: {{ node.nw_config.block_timeout || "N/D" }}</h5></li>
            </ul>
            <h3>Last Block Section:</h3>
             <ul class="last_block_list">
                <li><h5 v-if="node.last_block">HEIGHT : {{ node.last_block.block.data.height || 0 }}</h5></li>
                <li><h5 v-if="node.last_block">SIZE: {{ node.last_block.block.data.size || "N/D" }}</h5></li>
                <li><h5 v-if="node.last_block">LAST BLOCK HASH: {{ arrayBufferToBase64(node.last_block.block.data.prev_hash) || "N/D" }}</h5></li>
                <li><h5 v-if="node.last_block">TXS HASH: {{ arrayBufferToBase64(node.last_block.block.data.txs_hash) || "N/D" }}</h5></li>
                <li><h5 v-if="node.last_block">RXS HASH: {{ arrayBufferToBase64(node.last_block.block.data.rxs_hash) || "N/D" }}</h5></li>
                <li><h5 v-if="node.last_block">STATE HASH: {{ arrayBufferToBase64(node.last_block.block.data.state_hash) || "N/D" }}</h5></li>
            </ul>
            <h5 v-if="node.last_block">HASH: {{ arrayBufferToBase64(node.last_block.hash) || "N/D" }}</h5>
            <h5 v-if="node.unconfirmed_pool">UNCONFIRMED POOL: {{ node.unconfirmed_pool || "N/D" }}</h5>
            <h3>Peer2Peer Info:</h3>
             <ul class="p2p_list">
               <li><h5 v-if="node.p2p_info">PEER2PEER ADDRESS: {{ node.p2p_info.p2p_addr || "N/D" }}</h5></li>
               <li><h5 v-if="node.p2p_info">PEER2PEER PORT: {{ node.p2p_info.p2p_port || "N/D" }}</h5></li>
              <li><h5 v-if="node.p2p_info">PEER2PEER BOOTSTRAP ADDRESS: {{ node.p2p_info.p2p_bootstrap_addr || "N/D" }}</h5></li>
            </ul>
          </div>
          </div>
        </div>
      </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonBackButton } from '@ionic/vue';
import axios from 'axios';
export default {

  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonButtons,
    IonBackButton
  },
  data() {
    return {
      nodeData: [],
      id: this.$route.params.id,
      pollingStats: null,
    }
  },
  methods: {

    pollDataStats() {
      this.pollingStats = setInterval(() => {
        axios.get(/* process.env.BACKEND_URL +  */'https://monitor.affidaty.net/api/v1/nodesMonitor/nodes')
        .then(res => {
          // console.log(res.data);
          this.nodeData = res.data.find(el => el.public_key === this.id) || {};
        })
        .catch(err => {
          console.log(err, "Error: Request failed with status code 404");
        })
		}, 2000)
   },

    arrayBufferToBase64( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    },

  },

  computed: {
    node: function() {
      return this.nodeData;
    }
  },

  ionViewWillLeave() {
    clearInterval(this.pollingStats);
    // console.log(this.pollingStats, 'unmounted');
  },

  ionViewWillEnter() {
    this.pollDataStats()
  },

}
</script>

<style>

  .stats {
    padding: 30px;
  }

  .stats_box {
    margin: 10px 0;
    padding: 10px 10px;
    border-radius: 10px;
  }

  .details_title {
    text-align: center;
    margin-bottom: 15px;
  }

  .header_el {
    display: flex;
    justify-content: center;
    padding: 10px 10px;

  }
  .logo_img {
        height: 60px;
        margin-right: 10px;
  }

  h1 {
    text-transform: uppercase;
  }

  h5 {
    margin: 10px 0;
  }

  h4 {
    margin: 10px 0;
  }

  table {
    width: 100%;
    text-align: center;
  }

  table, th, td {
    border: 1px solid #989aa2;
    border-collapse: collapse;
  }

  .msgs_list > li {
    margin: 5px 15px;
  }

  .msgs_list > li > p {
    font-size: 12px;
  }

  .success {
    background-color: yellowgreen;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 12px;
  }

  .danger {
    background-color: red;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 12px;
  }

  @media screen and (max-width: 767px) {

    .header_el > .title {
      font-size: 20px;
    }

    .logo_img {
        display: none;
    }

    h1 {
      text-transform: uppercase;
      font-size: 20px;
    }

    h5 {
      margin: 10px 0;
      font-size: 14px;
    }

    h4 {
      margin: 10px 0;
      font-size: 15px;
    }

    h3 {
       font-size: 16px;
    }

     .stats_box {
      margin: 5px 0;
      padding: 0px 10px;
    }
  }

</style>
